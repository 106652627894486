<template>
  <main class="main page supporting sustainability CMS_pages">
    <div class="heroBanner" id="sustainability">
      <img src="../assets/images/heroes/header-sustainability.jpg">
      <h1 class="section__heading">Sustainability</h1>
    </div>
    <section class="section sectionContiner">
      <h1 class="section__heading">Our Story</h1>
      <div class="text text-sustainability">
        <!-- <img src="@/assets/images/sustainability1.png" style="grid-column: span 12"> -->
        <p class="text-sustainability__intro">As early as October 2020, when toTree was nothing more than ideas scribbled down on notepads and whiteboards, sustainability was a primary concern. While we wanted to help folks reap the many benefits of quality furniture, we were also determined to try and reduce the nearly 9 million tons of home furnishings that are dumped into landfills every year.</p>

        <p>To that end, we've established guidelines and protocols to work towards a greener planet.</p>
      </div>
    </section>
    <section class="section sectionContiner">
      <h1 class="section__heading">The ToTree Keep-it-Green Plan</h1>
      <div class="text keep-it-green">
        <div class="keep-it-green__columns">
          <div class="column">
            <img src="@/assets/images/sustainability/tree-hands.jpg">
            <h2>Products / Standards</h2>
            <ol>
            <li>We strive to ensure that our furniture offerings are either:
              <ul>
                <li>Made from wood/recycled wood and materials.</li>
                <li>Can be either partially or completely be recycled.</li>
              </ul>
            </li>
            <li>We will be setting annual goals aimed to markedly improve our sustainability standards in both products and services.</li>
            <li>We have carefully vetted our brands and only partner with those that are taking measures to reduce environmental waste. </li>
            </ol>
          </div>

          <div class="column">
            <img src="@/assets/images/sustainability/watering.jpg">
            <h2>Education / Resources</h2>
            <ol>
              <li>All toTree employees are required to complete the GreenLeaders Certification Training course.</li>
              <li>The toTree website will provide information about recycling, donating and the value of investing in quality furniture.</li>
            </ol>
          </div>

          <div class="column">
            <img src="@/assets/images/sustainability/recycle-tree.jpg">
            <h2>Recycling / Donations</h2>
            <ol>
              <li>Our One, toTree Service ensures that any (qualifying) furniture we are replacing will be either recycled or donated.</li>
              <li>In lieu of our One, toTree Service, we will provide clients with a specific list of non-profit organizations that take donations.</li>
              <li>We will be working with companies that extend the lifecycle of returned/damaged/unwanted furniture.</li>
            </ol>
          </div>
        </div>
      </div>

      <div class="text text-sustainability__bottom">
        <p>Sustainability in the home furnishings space is an imperfect science. There are countless factors including sourcing, materials, and construction that complicate and hinder the recycling process. Here at toTree, we are imperfect as well. While we would love to guarantee that the products we offer have zero environmental impact, we can&rsquo;t. But we can promise this: we will continue to strive towards that goal and never compromise our commitment to a greener planet no matter the effect on our bottom line.</p>

        <p>Of course, we&rsquo;re not the only ones who care about the environment. Many of you are as well. Since we couldn&rsquo;t possibly know everything on the subject, we encourage folks to send in any helpful tips, articles, organizations, etc., about recycling, donating, and sustainability. We&rsquo;ll share that information in our newsletter!</p>

        <p>
          Email us at: <a href="mailto:gogreen@totree.co">gogreen@totree.co</a>
        </p>

      </div>
</section>
  </main>
</template>

<style lang="scss" scoped>
.hero__image {
  background-image: url('../assets/images/heroes/header-sustainability.jpg');
  @include breakpoint($m) {
    background-position: center 75%;
  }

  @include breakpoint($l-up) {
    background-position: center 66%;
  }
}

.sustainability {
  .text-sustainability {
    &__intro {
      font-family: 'GT America Expanded';
      font-size: 1.25rem;
      line-height: 1.5rem;
      font-weight: 300;
      margin-bottom: calc(var(--gutter) * 2);
    }
    @include breakpoint($m-up) {
      grid-column-start: 3;
      grid-column-end: 11;
    }
  }

  .text-sustainability__bottom {
    color: #0B1326; font-weight: 300;
    a {color: #004021;}
    p{ font-size: 16px !important;
      &:last-child {
        margin-bottom: 0;
      }
    }
    @include breakpoint($m-up) {
      grid-column-start: 3;
      grid-column-end: 11;
    }
  }

  .keep-it-green {
    grid-column: span 12;
    background-color: $tt-off-white;
    padding: calc(var(--gutter) * 2);
    // margin: calc(var(--gutter) * 2) 2rem;
    border-radius: 20px; margin-bottom: 30px;
    @include breakpoint($s) {
      grid-column: span 12;
      padding: var(--gutter);
      margin: calc(var(--gutter) * 2) 0;
    }

    h1, h2, ol {
      color: $tt-green;
    }

    h2 {
      font-size: .9rem;
      text-align: center;
      margin: calc(var(--gutter) * 2) 0 var(--gutter);
    }

    img {
      width: 100%;
      max-width: 300px;
      display: block;
      margin: var(--gutter) auto;
    }
    ol {
      // font-size: 0.667rem;
      // line-height: normal;
      ul {
        li {
          list-style-type: disc;
        }
      }
    }
    ol, ul {
      li {
        margin-bottom: calc(var(--gutter) * 0.5);  line-height: normal; color: #0B1326;
      }
    }

    &__columns {
      // @include meta-text;
      display: flex;
      gap: var(--gutter);
      h2 {
        font-family: 'Sohne'; text-align: left; color: #0B1326; font-size: 1.25rem;
      }
      ol {
        font-size: 16px;
      }
      @include breakpoint($m-down) {
        display: block;
      }

      .column {
        flex: 1;
      }
    }
  }
}
</style>
